import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Reasons from "../components/reasons"

const AboutPage = () => (
  <Layout>
    <SEO title="Thank You" />
    <main className="main-container">
      <div className="title-container">
        <h1 className="page-title">Thank You</h1>
      </div>
      <div className="main-content-container">
        <p style={{ textAlign: "center" }}>
          Thanks for reaching out! We will be in touch soon.
        </p>
      </div>
      <Reasons bottom />
    </main>
  </Layout>
)

export default AboutPage
